import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Link from '../components/Link/Link'
import Layout from '../Layouts/Layout'
import { BlogRoll } from '../modules/BlogRoll/BlogRoll'
import { PrimaryButton } from '../components/Button/Button'
import PageContainer from '../components/PageContainer/PageContainer'
import Title from '../components/Title/Title'

const PrimarySpan = styled.span`
  color: ${({ theme }) => theme.color.primary};
`

const TagRoute = ({ data, pageContext }) => {
  const {
    allMarkdownRemark,
    site: {
      siteMetadata: { title },
    },
  } = data
  const { tag } = pageContext
  const { totalCount } = allMarkdownRemark

  return (
    <Layout>
      <Helmet title={`${tag} | ${title}`} />

      <Title>{tag}</Title>
      <PageContainer>
        <p>
          Total items with tag:{' '}
          <PrimarySpan>
            "
            {tag}
            "
          </PrimarySpan>
          : {totalCount}
        </p>
        <BlogRoll data={data} />

        <PrimaryButton as={Link} to="/tags/">
          Browse all tags
        </PrimaryButton>
      </PageContainer>
    </Layout>
  )
}

export default TagRoute

TagRoute.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  pageContext: PropTypes.objectOf(PropTypes.any),
}

TagRoute.defaultProps = {
  data: {},
  pageContext: {},
}

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            tags
            featureImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
